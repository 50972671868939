<template>
    <button
        :type="$page.props.type"
        class="h-11 hover:bg-secondary-bg-hover inline-flex items-center px-4 py-2 bg-secondary-bg border border-transparent rounded-md font-bold text-sm text-font-color uppercase tracking-widest  focus:bg-gray-700 active:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition ease-in-out duration-150"
    >
        <slot />
    </button>
</template>
<script>
import {defineProps} from 'vue';

const props = defineProps({
    type: {
        type: String,
        default: 'submit'
    }
});
</script>
